import React from "react";
import TagManager from "../js/utilities/tag-manager";

export default class CTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
    };
  }

  componentDidMount() {
    this.buildCta();
  }

  trackEvent(event) {
    TagManager.dataLayer({
      dataLayer: {
        type: "event",
        event: "Interaction",
        event_category: "Conversion",
        event_type: "CTA Click",
        event_detail: this.state.content.title,
      },
    });
  }

  buildCta() {
    var ctaObject;

    if (this.props.type === "learn") {
      ctaObject = {
        type: this.props.type,
        title: "Get to know PRIZM<sup>®</sup> segmentation better",
        subtitle: "We've organized our best informational content into a short, three-part email series",
        cta: "Sign up",
        cta_link: "https://go.environicsanalytics.com/PRIZM-Get-Started.html",
      };
    }

    if (this.props.type === "questions") {
      ctaObject = {
        type: this.props.type,
        title: "Have Questions?",
        subtitle: "We're here to help.",
        cta: "Contact Us",
        cta_link: "https://go.environicsanalytics.com/PRIZM-Contact-Us.html",
      };
    }

    this.setState({ content: ctaObject });
  }

  render() {
    return (
      <section className={`cta cta--${this.props.type}`}>
        <div className={`cta cta--${this.props.type}__inner`}>
          <h3 className="title title--secondary" dangerouslySetInnerHTML={{ __html: this.state.content.title }} />
          {this.state.content.subtitle ? <p>{this.state.content.subtitle}</p> : null}
          <button className="primary-cta" onClick={() => this.props.showContactDetails()}>{this.state.content.cta}</button>
        </div>
      </section>
    );
  }
}
